
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Enlace } from "@/interfaces/Enlace";
import { deleteEnlace, getEnlace, updateEnlace } from "@/services/EnlaceService";
// import { Medico } from "@/interfaces/Medico";
// import { getMedicos } from "@/services/MedicoService";
// import { Cliente } from "@/interfaces/Cliente";
// import { getClientes } from "@/services/ClienteService";

export default defineComponent({
  name: "enlace-list",
  components: {
    Navbar,
  },
  data() {
    return {
      currentEnlace: {} as Enlace,
      // medicos: [] as Medico[],
      // clientes: [] as Cliente[],
    };
  },
  methods: {
    async loadEnlace(id: string) {
      try {
        const { data } = await getEnlace(id);
        this.currentEnlace = data;
      } catch (error) {
        //console.error(error);
      }
    },

    //     async loadClientes() {
    //   try {
    //     const res = await getClientes();
    //     this.clientes = res.data;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    // async loadMedicos() {
    //   try {
    //     const res = await getMedicos();
    //     this.medicos = res.data;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateEnlace(this.$route.params.id, this.currentEnlace);
          this.$router.push("/enlaces");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteEnlace(this.$route.params.id);
          this.$router.push("/enlaces");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    // this.loadMedicos();
    // this.loadClientes();
    if (typeof this.$route.params.id === "string") {
      this.loadEnlace(this.$route.params.id);
    }
  },
});
