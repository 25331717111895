
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Usuario } from "@/interfaces/Usuario";
import {
  deleteUsuario,
  getUsuario,
  updateUsuario,
} from "@/services/UsuarioService";
import numeral from "numeral";

export default defineComponent({
  name: "usuario-list",
  components: {
    Navbar,
  },
  data() {
    return {
      currentUsuario: {} as Usuario,
      estado: {} as object,
    };
  },
  methods: {
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },
    async loadUsuario(id: string) {
      try {
        const { data } = await getUsuario(id);
        this.currentUsuario = data;
        this.estado = this.currentUsuario.estado;
      } catch (error) {
        //console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateUsuario(this.$route.params.id, this.currentUsuario);
          this.$router.push("/usuarios");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteUsuario(this.$route.params.id);
          this.$router.push("/usuarios");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadUsuario(this.$route.params.id);
    }
  },
});
