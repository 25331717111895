
import numeral from 'numeral';
import moment from 'moment';

export default {
  props: [
    'recepcions'
  ],
  methods : {
    formatNumber(value: number) {
      return numeral(value).format("000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("DD/MM/YYYY hh:mm");
    },

  }
}
