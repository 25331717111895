
import Filters from '@/components/Directorio/FiltersComponent.vue'
import DirectoriosComponent from '@/components/Directorio/DirectoriosComponent.vue'
import Navbar from "@/components/Navbar.vue";
import { Directorio } from "@/interfaces/Directorio";
import { getDirectorios } from "@/services/DirectorioService";

export default {
  name: 'App',

  components: {
    Filters,
    DirectoriosComponent,
    Navbar,
  },

  data () {
    return {
      directorios: [] as Directorio[],
      str: '',
      type: ''
      
    }
  },
  
  methods: {
    async filterDirectorios (catName: string) {
      try {
        const res = await getDirectorios();
        this.directorios = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (catName !== 'Todos') {
        this.directorios = this.directorios.filter((directorio: Directorio) => {
          return directorio.tipo === catName
        })
      }
    },

    async search (term: string) {
      try {
        const res = await getDirectorios();
        this.directorios = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.directorios = this.directorios.filter((directorio: Directorio) => {
        return directorio.medico.toLowerCase().includes(term.toLowerCase())
      })
    },

    async loadDirectorios() {
      try {
        const res = await getDirectorios();
        this.directorios = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
  },

  mounted() {
    this.loadDirectorios();
  },

  }

