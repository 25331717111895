
import Filters from '@/components/Recepcion/FiltersComponent.vue'
import RecepcionsComponent from '@/components/Recepcion/RecepcionsComponent.vue'
import Navbar from "@/components/Navbar.vue";
import { Recepcion } from "@/interfaces/Recepcion";
import { getRecepcions } from "@/services/RecepcionService";

export default {
  name: 'App',

  components: {
    Filters,
    RecepcionsComponent,
    Navbar,
  },

  data () {
    return {
      recepcions: [] as Recepcion[],
      str: '',
      type: ''
      
    }
  },
  
  methods: {
    async filterRecepcions (catName: string) {
      try {
        const res = await getRecepcions();
        this.recepcions = res.data;
      } catch (error) {
        // console.error(error);
      }
      // if (catName !== 'Todos') {
      //   this.recepcions = this.recepcions.filter((recepcion: Recepcion) => {
      //     return recepcion.tipo === catName
      //   })
      // }
    },

    async search (term: string) {
      try {
        const res = await getRecepcions();
        this.recepcions = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.recepcions = this.recepcions.filter((recepcion: Recepcion) => {
        return recepcion.medico.toLowerCase().includes(term.toLowerCase())
      })
    },

    async loadRecepcions() {
      try {
        const res = await getRecepcions();
        this.recepcions = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
  },

  mounted() {
    this.loadRecepcions();
  },

  }

