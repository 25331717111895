
import { defineComponent } from "vue";
import { Recepcion } from "@/interfaces/Recepcion";
import { deleteRecepcion, getRecepcion, updateRecepcion } from "@/services/RecepcionService";
import moment from 'moment';

export default defineComponent({
  name: "recepcion-list",
  components: {
  },
  data() {
    return {
      currentRecepcion: {} as Recepcion,
    };
  },
  methods: {

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("DD/MM/YYYY hh:mm a");
      // return moment(out).format('MMMM Do YYYY, h:mm:ss a');
    },

    async loadRecepcion(id: string) {
      try {
        const { data } = await getRecepcion(id);
        this.currentRecepcion = data;
      } catch (error) {
        //console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateRecepcion(this.$route.params.id, this.currentRecepcion);
          alert("Recepcion actualizado con éxito")
          this.$router.push("/recepcions");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteRecepcion(this.$route.params.id);
          alert("Recepcion eliminado con éxito")
          this.$router.push("/recepcions");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadRecepcion(this.$route.params.id);
    }
  },
});
