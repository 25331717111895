
import { defineComponent } from "vue";
import { Documento } from "@/interfaces/Documento";
import {
  deleteDocumento,
  getDocumento2,
  updateDocumento,
} from "@/services/DocumentoService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "documento-list",
  data() {
    return {
      cargando: false,
      documento: [] as any[],
      documento2: {} as Documento,
      totales: {},
    };
  },
  methods: {
    valorTotal() {
      this.totales.documentos = this.documento.length;
      this.totales.credito = this.documento.reduce(
        (accum: any, item: any) => accum + item.credito,
        0
      );
      this.totales.debito = this.documento.reduce(
        (accum: any, item: any) => accum + item.debito,
        0
      );
    },
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },
    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("DD/MM/YYYY");
    },
    async loadDocumento(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getDocumento2(id);
        this.documento = data;
        this.documento2 = this.documento[0];
        this.valorTotal();
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();
      this.$router.push(`/documentosfac/${this.documento2._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateDocumento(this.$route.params.id, this.documento);
          this.$router.push("/documentosfac");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteDocumento(this.$route.params.id);
          this.$router.push("/documentosfac");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  async mounted() {
    if (typeof this.$route.params.id === "string") {
      await this.loadDocumento(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
