import { Medico } from "@/interfaces/Medico";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const getMedicos = async (): Promise<AxiosResponse<Medico[]>> =>
  await axios.get("/medicos/get");

export const getMedico = async (id: string): Promise<AxiosResponse<Medico>> =>
  await axios.get(`/medicos/GetById/${id}`);

  export const getOneMedico = async (): Promise<AxiosResponse<Medico>> =>
  await axios.get("/medicos/GetOne");

export const createMedico = async (medico: Medico): Promise<AxiosResponse> =>
  await axios.post("/medicos/Save", medico);

export const createMedicoa = async (medico: Medico): Promise<AxiosResponse> =>
  await axios.post("/medicos/Savea", medico);

  export const eliminateMedicos = async (medico: Medico): Promise<AxiosResponse> =>
  await axios.post("/medicos/delete", medico);

export const updateMedico = async (
  id: string,
  newMedico: Medico
): Promise<AxiosResponse<Medico>> => await axios.put(`/medicos/Update/${id}`, newMedico);

export const deleteMedico = async (id: string): Promise<AxiosResponse> =>
  await axios.delete(`/medicos/Delete/${id}`);
