
import numeral from "numeral";
import moment from "moment";
export default {
  props: ["facturas"],
  data() {
    return {
      data: false,
      totales: {
        facturas: 0,
        total: 0,
      },
    };
  },
  methods: {
    valorTotal() {
      this.totales.total = this.facturas.reduce(
        (accum: any, item: any) => accum + item.totalGeneral,
        0
      );
      this.totales.facturas = this.facturas.length;
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("DD/MM/YYYY");
    },
  },


  updated() {
    this.valorTotal();
    // this.data = !this.data;
  },
};
