
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Factura } from "@/interfaces/Factura";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";
import {
  deleteFactura,
  getFactura,
  updateFactura,
} from "@/services/FacturaService";
import numeral from "numeral";

export default defineComponent({
  name: "factura-list",
  components: {
    Navbar,
  },
  data() {
    return {
      currentFactura: {} as Factura,
      estado: {} as object,
      clientes: [] as Cliente[],
    };
  },
  methods: {
    async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },
    async loadFactura(id: string) {
      try {
        const { data } = await getFactura(id);
        this.currentFactura = data;
        this.estado = this.currentFactura.estado;
      } catch (error) {
        //console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          this.currentFactura.userMod = this.$store.state.user.usuario
          await updateFactura(this.$route.params.id, this.currentFactura);
          this.$router.push("/facturas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteFactura(this.$route.params.id);
          this.$router.push("/facturas");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    this.loadClientes();
    if (typeof this.$route.params.id === "string") {
      this.loadFactura(this.$route.params.id);
    }
  },
});
