<template>
  <div>
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/login">Login</router-link> -->
    </div>

    <router-view />
  </div>
</template>
