
const filters = [
  'Todos',
  'Médico',
  'Administrador',
  'Standard'
]

export default {
  props: [
    'filterUsuarios',
    'search',
    'filteredUsuarios',
  ],

  data () {
    return {
      filters,
      term: '',
      filters2: [] as []
    }
  },
}
