
import Navbar from "@/components/Navbar.vue";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";
import numeral from "numeral";
export default {
  name: "DashboardView",
  components: {
    Navbar,
  },

  data() {
    return {
      medicos: [] as Medico[],
    };
  },

  methods: {

    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    async filterEstado(term: string) {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.medicos = this.medicos.filter((medico: Medico) => {
        return medico.emisor.toLowerCase().includes(term.toLowerCase());
      });
    },
  },

  mounted() {
    this.filterEstado(this.$store.state.user.usuario);
    // this.loadMedicos();
  },
};
