
import numeral from "numeral";
import moment from "moment";

export default {
  props: ["documentos"],
  data() {
    return {
      data: false,
      totales: {
        documentos: 0,
        bruto: 0,
        retension: 0,
        neto: 0,
      },
    };
  },
  methods: {
    valorTotal() {
      this.totales.documentos = this.documentos.length;
      this.totales.reclamacion = this.documentos.reduce(
        (accum: any, item: any) => accum + item.reclamacion,
        0
      );
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },

    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(4, "h");
      return moment(out).format("DD/MM/YYYY");
    },
  },

  updated() {
    this.valorTotal();
    // this.data = !this.data;
  },
};
