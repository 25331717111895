
import Navbar from"@/components/Navbar.vue";
import { defineComponent } from"vue";
import { Directorio } from"@/interfaces/Directorio";
import { createDirectorio, eliminateDirectorios, createDirectorioa } from"@/services/DirectorioService";
import { getOneDirectorio } from "@/services/DirectorioService";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";

export default defineComponent({
  name:"directorios-form",
  components: {
    Navbar,
  },  
  data() {
    return {
      directorio: {} as Directorio,
      oneDirectorio: {} as Directorio,
      one: {},
      nextNo: Number,
      medicos: [] as Medico[],
      clientes: [] as Cliente[],
    };
  },
  
  mounted() {
    this.loadMedicos();
    this.loadClientes();
  },

  methods: {
    async loadOneDirectorio() {
      try {
        const res = await getOneDirectorio();
        this.oneDirectorio = res.data;
        this.one = this.oneDirectorio[0];
        this.nextNo = this.one.no + 1;
        this.directorio.no = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveDirectorioa() {
      try {
        const res = await createDirectorioa(this.servicio);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    async saveDirectorio() {
        try {
          try {
          const res = await getOneDirectorio();
          this.oneDirectorio = res.data;
          this.one = this.oneDirectorio[0];
          this.nextNo = this.one.no + 1;
          this.directorio.no = this.nextNo;
        } catch (error) {
          // console.error(error);
        }
          const res = await createDirectorio(this.directorio);
          // console.log(res);
          this.definingFields();
          this.focus();
        } catch (error) {
          // console.error(error);
        }
    },

    async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async deleteAllDirectorios() {
      try {
        const res = await eliminateDirectorios(this.directorio);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    definingFields() {
              this.directorio.tipo =""
              this.directorio.medico =""
              this.directorio.cliente =""
              this.directorio.descripcion =""
    },

    focus() {
      document.getElementById("tipo").focus();
    },

  },
});
