
import { getMedicos } from "@/services/MedicoService";
const filters = ["Todos", "Ajuste", "Reenvío", "Pérdida"];

export default {
  props: ["filterDocumentos", "search", "filteredDocumentos"],

  methods: {

    toggleFilter() {
      this.a = !this.a;
    },
    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    focus() {
      document.getElementById("search").focus();
    },
  },

  data() {
    return {
      a: false,
      filters,
      term: "",
      filters2: [] as [],
      medicos: [] as any,
    };
  },
  mounted() {
    this.loadMedicos();
    this.focus();
  },
};
