
import moment from "moment";
import Navbar from "@/components/Navbar.vue";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";
import {
  getdigitacion,
  getDocumentosFac,
  getfacsbymed,
  getprefacsbymed,
  getLotes,
  getBalanceCliente,
} from "@/services/DocumentoService";
import numeral from "numeral";
import { Documento } from "@/interfaces/Documento";
export default {
  name: "DashboardView",
  components: {
    Navbar,
  },

  data() {
    return {
      balancesClientes: [
        {
          _id: {
            descCliente: "Semma",
          },
          factura: 16860,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Mapfre Salud",
          },
          factura: 144900,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Monumental",
          },
          factura: 1000,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Grupo Medico Asociado",
          },
          factura: 1000,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Yunen",
          },
          factura: 22560,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Humano",
          },
          factura: 99338.48,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Universal",
          },
          factura: 76718,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "UASD",
          },
          factura: 26300,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Renacer",
          },
          factura: 1000,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Futuro",
          },
          factura: 13685,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Idoppril",
          },
          factura: 18600,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Primera De Humano",
          },
          factura: 153769.12,
          recibo: 0,
          retension: 3660,
          neto: 32940,
        },
        {
          _id: {
            descCliente: "Reservas",
          },
          factura: 4375,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Aps",
          },
          factura: 2200,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
        {
          _id: {
            descCliente: "Colegio Médico Dominicano",
          },
          factura: 500,
          recibo: 0,
          retension: 0,
          neto: 0,
        },
      ],
      digitacion: [] as any,
      documento: {} as any,
      documentos: [] as Documento[],
      documentos2: [] as Documento[],
      totales: {
        documentos: 0,
        bruto: 0,
        retension: 0,
        neto: 0,
      },
      // reclamst: [],
    };
  },

  methods: {
    formatNumber2(value: number) {
      return numeral(value).format("0,0");
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(4, "h");
      return moment(out).format("DD/MM/YYYY");
    },

    async filterDocumentos(catName: string) {
      try {
        const res = await getDocumentosFac();
        this.documentos = res.data;
      } catch (error) {
        // console.error(error);
      }
      // if (catName !== "Todos") {
      //   this.documentos = this.documentos.filter((documento: Documento) => {
      //     return documento.tipo === catName;
      //   });
      // }
    },
    valorTotal() {
      this.totales.documentos = this.documentos.length;
      this.totales.factura = this.documentos.reduce(
        (accum: any, item: any) => accum + item.factura,
        0
      );
    },

    valorTotalt() {
      this.totales.documentost = this.reclamst.length;

      // this.totales.reclamaciont = this.reclams.reduce(
      //   (accum: any, item: any) => accum + item.reclamacion,
      //   0
      // );

      // this.totales.documentos3 = this.reclams2.length;
      // this.totales.reclamacion3 = this.reclams2.reduce(
      //   (accum: any, item: any) => accum + item.reclamacion,
      //   0
      // );
    },

    async loadMonitor() {
      try {
        const res = await getdigitacion();
        this.digitacion = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    async filterEstado(term: string) {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.medicos = this.medicos.filter((medico: Medico) => {
        return medico.emisor.toLowerCase().includes(term.toLowerCase());
      });
    },

    async loadFacturas() {
      try {
        const res = await getfacsbymed(this.documento);
        this.documentos = res.data;
        this.totales.documentos = this.documentos.length;
        this.totales.factura = this.documentos.reduce(
          (accum: any, item: any) => accum + item.factura,
          0
        );
      } catch (error) {
        // console.error(error);
      }
    },

    async loadPrecturas() {
      try {
        const res = await getprefacsbymed(this.documento);
        this.documentos2 = res.data;
        this.totales.documentos2 = this.documentos2.length;
        this.totales.factura2 = this.documentos2.reduce(
          (accum: any, item: any) => accum + item.prefactura,
          0
        );
      } catch (error) {
        // console.error(error);
      }
    },

    async loadLotes() {
      // this.toggleLoading();
      try {
        const res = await getLotes();
        this.reclamst = res.data;
        this.valorTotalt();
      } catch (error) {
        // console.error(error);
      }
      // this.toggleLoading();
    },

    async loadBalanceCliente() {
      try {
        const res = await getBalanceCliente(this.documento);
        this.balancesClientes = res.data;
        this.totales.cantBalancesClientes = this.balancesClientes.length;
        this.totales.facturaBalancesClientes = this.balancesClientes.reduce(
          (accum: any, item: any) => accum + item.factura,
          0
        );
        this.totales.reciboBalancesClientes = this.balancesClientes.reduce(
          (accum: any, item: any) => accum + item.recibo,
          0
        );
        this.totales.balanceBalancesClientes =
          this.totales.facturaBalancesClientes -
          this.totales.reciboBalancesClientes;
      } catch (error) {
        // console.error(error);
      }
    },
  },

  mounted() {
    this.documento.medico = this.$store.state.user.usuario;
    this.loadFacturas();
    this.loadPrecturas();
    this.loadMonitor();
    this.loadLotes();
    this.loadBalanceCliente();
  },
};
