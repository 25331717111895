
import Navbar from "@/components/Navbar.vue";
import { Documento } from "@/interfaces/Documento";
import {
  getCuentasCobrar,
  updateDocumento,
  getOneDocumento,
  createDocumento,
} from "@/services/DocumentoService";
import numeral from "numeral";
import moment from "moment";
import { getClientesShort } from "@/services/ClienteService";
import { Cliente } from "@/interfaces/Cliente";
export default {
  name: "DashboardView",
  components: {
    Navbar,
  },

  data() {
    return {
      selectedDoc: {} as Documento,
      documento3: {} as Documento,
      selectedCliente: {} as Cliente,
      cargando: false,
      // medicos: [] as Medico[],
      totales: {},
      balances: [] as any,
      oneDocumento: {} as Documento,
      one: {},
      nextNo: Number,
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      recibos: [] as any,
      clientes: [] as Cliente[],
      showModal: false,
      showModalCobrar: false,
      documentoPago: {} as Documento,
      valorPagar: 0,
      viaPagar: "Transferencia",
    };
  },

  methods: {
    async cobrar(balance: Documento) {
      this.documentoPago = balance;
      this.documentoPago.pago = true;
      await updateDocumento(this.documentoPago._id, this.documentoPago);

      this.toggleLoading();
      try {
        try {
          const res = await getOneDocumento();
          this.oneDocumento = res.data;
          this.one = this.oneDocumento[0];
          this.nextNo = this.one.no + 1;
          this.documento3.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.documento3.userReg = this.$store.state.user.usuario;
        this.documento3.fechaProceso = new Date();
        this.documento3.fechaEfectivo = new Date();
        this.documento3.medico = balance.medico;
        this.documento3.descCliente = balance.descCliente;
        this.documento3.principal = balance.principal;
        this.documento3.tipo = "Recibo";
        if (balance.subTipo == "Refactura") {
          this.documento3.subTipo = "Reenvío";
          this.documento3.cantRereen = 1;
          this.documento3.reciboreen = this.valorPagar;
          this.documento3.retension = this.valorPagar * 0.1;
          this.documento3.neto =
            this.documento3.reciboen - this.documento3.retension;
        } else {
          this.documento3.subTipo = "Envío";
          this.documento3.cantReen = 1;
          this.documento3.reciboen = this.valorPagar;
          this.documento3.retension = this.valorPagar * 0.1;
          this.documento3.neto =
            this.documento3.reciboen - this.documento3.retension;
        }
        this.documento3.cantRe = 1;
        this.documento3.recibo = this.valorPagar;
        this.documento3.credito = 0;
        this.documento3.debito = 0;
        this.documento3.metodo = this.viaPagar;
        this.documento3.literalAfecta = balance.literal;
        this.documento3.ncfAfecta = balance.ncf;
        const res = await createDocumento(this.documento3);
        // this.$router.push("/documentos/");
      } catch (error) {
        // // console.error(error);
      }
      await this.toggleLoading();

      // this.showModalCobrarMethod();

      this.loadCuentasCobrar();
      this.valorPagar = 0;
      this.showModalCobrar = false;
    },

    async search(term: string) {
      this.selectedCliente = this.clientes.filter((cliente: Cliente) => {
        return cliente.descripcion.toLowerCase().includes(term.toLowerCase());
      });
      this.showModalMethod();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    showModalCobrarMethod(balance: Documento) {
      this.selectedDoc = balance;
      this.showModalCobrar = !this.showModalCobrar;
    },

    difDate(fechaInicio: Date, fechaFin: Date) {
      var timeFechaInicio = fechaInicio.getTime();
      var timeFechaFin = fechaFin.getTime();

      var diff = timeFechaFin - timeFechaInicio;

      console.log(diff / (1000 * 60 * 60 * 24));
    },

    async loadClientes() {
      try {
        const res = await getClientesShort();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    newFormatDate(dateValue: Date) {
      moment.locale("es");
      let out = moment(dateValue).add(45, "days");
      return moment(out)
        .endOf("day")
        .fromNow();
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("DD/MM/YYYY");
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    valorTotal() {
      this.totales.cuentas = this.balances.length;
      this.totales.facturado = this.balances.reduce(
        (accum: any, item: any) => accum + item.facturado,
        0
      );
      this.totales.pagado = this.balances.reduce(
        (accum: any, item: any) => accum + item.pagado,
        0
      );
      this.totales.balance = this.balances.reduce(
        (accum: any, item: any) => accum + item.balance,
        0
      );
    },
    async loadCuentasCobrar() {
      this.toggleLoading();
      try {
        const res = await getCuentasCobrar();
        this.balances = res.data;
      } catch (error) {
        // console.error(error);
      }
      this.toggleLoading();
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    // async filterEstado(term: string) {
    //   this.toggleLoading();
    //   try {
    //     const res = await getDocumentosFacInt();
    //     this.balances = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    //   this.balances = this.balances.filter((balance: any) => {
    //     return balance._id.medico.toLowerCase().includes(term.toLowerCase());
    //   });
    //   this.valorTotal();
    //   this.toggleLoading();
    // },
  },

  mounted() {
    // this.filterEstado(this.$store.state.user.usuario);
    // this.loadDocumentos();
    this.loadClientes();
    this.loadCuentasCobrar();
  },
};
