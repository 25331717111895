
import Navbar from"@/components/Navbar.vue";
import { defineComponent } from"vue";
import { Enlace } from"@/interfaces/Enlace";
import { createEnlace, eliminateEnlaces, createEnlacea } from"@/services/EnlaceService";
import { getOneEnlace } from "@/services/EnlaceService";
// import { Medico } from "@/interfaces/Medico";
// import { getMedicos } from "@/services/MedicoService";
// import { Cliente } from "@/interfaces/Cliente";
// import { getClientes } from "@/services/ClienteService";

export default defineComponent({
  name:"enlaces-form",
  components: {
    Navbar,
  },  
  data() {
    return {
      enlace: {} as Enlace,
      oneEnlace: {} as Enlace,
      one: {},
      nextNo: Number,
      // medicos: [] as Medico[],
      // clientes: [] as Cliente[],
    };
  },
  
  mounted() {
    // this.loadMedicos();
    // this.loadClientes();
  },

  methods: {
    async loadOneEnlace() {
      try {
        const res = await getOneEnlace();
        this.oneEnlace = res.data;
        this.one = this.oneEnlace[0];
        this.nextNo = this.one.no + 1;
        this.enlace.no = this.nextNo;
      } catch (error) {
        // console.error(error);
      }
    },

    async saveEnlacea() {
      try {
        const res = await createEnlacea(this.servicio);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    async saveEnlace() {
        try {
          try {
          const res = await getOneEnlace();
          this.oneEnlace = res.data;
          this.one = this.oneEnlace[0];
          this.nextNo = this.one.no + 1;
          this.enlace.no = this.nextNo;
        } catch (error) {
          // console.error(error);
        }
          const res = await createEnlace(this.enlace);
          // console.log(res);
          this.definingFields();
          this.focus();
        } catch (error) {
          // console.error(error);
        }
    },

    // async loadClientes() {
    //   try {
    //     const res = await getClientes();
    //     this.clientes = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // },

    // async loadMedicos() {
    //   try {
    //     const res = await getMedicos();
    //     this.medicos = res.data;
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // },

    async deleteAllEnlaces() {
      try {
        const res = await eliminateEnlaces(this.enlace);
        // console.log(res);
      } catch (error) {
        // console.error(error);
      }
    },

    definingFields() {
              this.enlace.tipo =""
              this.enlace.nombre =""
              this.enlace.descripcion =""
              this.enlace.enlace =""
              this.enlace.credenciales =""
    },

    focus() {
      document.getElementById("tipo").focus();
    },

  },
});
