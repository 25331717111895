
import { defineComponent } from "vue";
import { Medico } from "@/interfaces/Medico";
import {
  deleteMedico,
  getMedico,
  updateMedico,
} from "@/services/MedicoService";
import numeral from "numeral";

export default defineComponent({
  name: "medico-list",
  components: {},
  data() {
    return {
      currentMedico: {} as Medico,
      estado: {} as object,
    };
  },
  methods: {
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },
    async loadMedico(id: string) {
      try {
        const { data } = await getMedico(id);
        this.currentMedico = data;
        this.estado = this.currentMedico.estado;
      } catch (error) {
        //console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateMedico(this.$route.params.id, this.currentMedico);
          this.$router.push("/medicos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteMedico(this.$route.params.id);
          this.$router.push("/medicos");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadMedico(this.$route.params.id);
    }
  },
});
