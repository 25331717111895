
import { defineComponent } from "vue";
import { Directorio } from "@/interfaces/Directorio";
import { deleteDirectorio, getDirectorio, updateDirectorio } from "@/services/DirectorioService";

export default defineComponent({
  name: "directorio-list",
  components: {
  },
  data() {
    return {
      currentDirectorio: {} as Directorio,
    };
  },
  methods: {
    async loadDirectorio(id: string) {
      try {
        const { data } = await getDirectorio(id);
        this.currentDirectorio = data;
      } catch (error) {
        //console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateDirectorio(this.$route.params.id, this.currentDirectorio);
          alert("Directorio actualizado con éxito")
          this.$router.push("/directorios");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteDirectorio(this.$route.params.id);
          alert("Directorio eliminado con éxito")
          this.$router.push("/directorios");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadDirectorio(this.$route.params.id);
    }
  },
});
