
import numeral from "numeral";

export default {
  props: ["clientes"],

  methods: {
    formatNumber(value: number) {
      return numeral(value).format("000000");
    },
  },
};
