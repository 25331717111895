
import { defineComponent } from "vue";
import { Enlace } from "@/interfaces/Enlace";
import { deleteEnlace, getEnlace, updateEnlace } from "@/services/EnlaceService";

export default defineComponent({
  name: "enlace-list",
  components: {
  },
  data() {
    return {
      currentEnlace: {} as Enlace,
    };
  },
  methods: {
    async loadEnlace(id: string) {
      try {
        const { data } = await getEnlace(id);
        this.currentEnlace = data;
      } catch (error) {
        //console.error(error);
      }
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateEnlace(this.$route.params.id, this.currentEnlace);
          alert("Enlace actualizado con éxito")
          this.$router.push("/enlaces");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteEnlace(this.$route.params.id);
          alert("Enlace eliminado con éxito")
          this.$router.push("/enlaces");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadEnlace(this.$route.params.id);
    }
  },
});
