
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Medico } from "@/interfaces/Medico";
import {
  createMedico,
  eliminateMedicos,
  createMedicoa,
} from "@/services/MedicoService";
import { getOneMedico } from "@/services/MedicoService";

export default defineComponent({
  name: "medicos-form",
  components: {
    Navbar,
  },
  data() {
    return {
      medico: {} as Medico,
      oneMedico: {} as Medico,
      one: {},
      nextNo: Number,
    };
  },

  mounted() {
    // this.loadOneMedico();
  },

  methods: {
    async loadOneMedico() {
      try {
        const res = await getOneMedico();
        this.oneMedico = res.data;
        this.one = this.oneMedico[0];
        this.nextNo = this.one.no + 1;
        this.medico.no = this.nextNo;
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveMedicoa() {
      try {
        const res = await createMedicoa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveMedico() {
      try {
        try {
          const res = await getOneMedico();
          this.oneMedico = res.data;
          this.one = this.oneMedico[0];
          this.nextNo = this.one.no + 1;
          this.medico.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        const res = await createMedico(this.medico);
        // // console.log(res);
        this.definingFields();
        this.focus();
      } catch (error) {
        // // console.error(error);
      }
    },

    async deleteAllMedicos() {
      try {
        const res = await eliminateMedicos(this.medico);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      this.medico.emisor = "";
      this.medico.direccionEmisor = "";
      this.medico.rncEmisor = "";
      this.medico.sexo = "";
      this.medico.especialidad = "";
      this.medico.exq = "";
      this.medico.cmd = "";
      this.medico.telefono = "";
      this.medico.email = "";
      this.medico.claveDGII = "";
      this.medico.observaciones = "";
      this.medico.estatus = "";
    },

    focus() {
      document.getElementById("rncEmisor").focus();
    },
  },
});
