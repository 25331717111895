
import Navbar from "@/components/Navbar.vue";
import { defineComponent } from "vue";
import { Recibo } from "@/interfaces/Recibo";
import { createBalance } from "@/services/BalanceService";
import {
  createRecibo,
  eliminateRecibos,
  createReciboa,
  getOneRecibo,
} from "@/services/ReciboService";
import { updateMedico } from "@/services/MedicoService";
import { Medico } from "@/interfaces/Medico";
import { getMedicos } from "@/services/MedicoService";
import { Cliente } from "@/interfaces/Cliente";
import { getClientes } from "@/services/ClienteService";
import numeral from "numeral";

export default defineComponent({
  name: "recibos-form",
  components: {
    Navbar,
  },
  data() {
    return {
      balance: {},
      recibo: {} as Recibo,
      oneRecibo: {} as Recibo,
      one: {},
      nextNo: Number,
      medicos: [] as Medico[],
      medicoSelected: [],
      clientes: [] as Cliente[],
      clienteSelected: [],
      descripcionCliente: "",
      ncfSelected: [],
      buscComp: "",
      compiDef: [],
      newComprobante: {
        compNoGuberAsig: 8,
      },
    };
  },

  mounted() {
    this.loadOneRecibo();
    this.loadMedicos();
    this.loadClientes();
    this.defFechas();
  },

  methods: {
    calcRecibo() {
      (this.recibo.retension = this.recibo.bruto * 0.1),
        (this.recibo.neto = this.recibo.bruto * 0.9);
    },
    // async handleUpdate() {
    //   alert("Recibo Generado Exitosamente.");
    //   // if (this.recibo.literal == "B15") {
    //   //   this.medicoSelected[0].compGuberAsig += 1;
    //   // } else {
    //   //   this.medicoSelected[0].compNoGuberAsig += 1;
    //   // }
    //   this.medicoSelected[0].estado.pagado += this.recibo.bruto;
    //   this.medicoSelected[0].estado.retension += this.recibo.retension;
    //   this.medicoSelected[0].estado.ingresos += this.recibo.neto;
    //   this.medicoSelected[0].estado.porCobrar -= this.recibo.bruto;
    //   await updateMedico(this.medicoSelected[0]._id, this.medicoSelected[0]);
    // },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    asignarNCF() {
      this.recibo.ncf = "";
      this.recibo.literal = this.clienteSelected[0].tipoComprobante.substring(
        0,
        3
      );

      if (this.recibo.literal == "B15") {
        this.recibo.ncf = this.medicoSelected[0].compGuberAsig;
        this.recibo.vencimiento = this.medicoSelected[0].fechGuberAsig;
      } else {
        this.recibo.ncf = this.medicoSelected[0].compNoGuberAsig;
        this.recibo.vencimiento = this.medicoSelected[0].fechNoGuberAsig;
      }
    },

    defFechas() {
      this.recibo.fechaRecibo = new Date();
      this.recibo.fechaEntrega = new Date();
    },

    selectMedico(term: string) {
      this.medicoSelected = this.medicos.filter((medico: Medico) => {
        return medico.emisor.toLowerCase().includes(term.toLowerCase());
      });

      this.recibo.direccionEmisor = this.medicoSelected[0].direccionEmisor;

      this.recibo.rncEmisor = this.medicoSelected[0].rncEmisor;

      this.asignarNCF();
    },

    async loadMedicos() {
      try {
        const res = await getMedicos();
        this.medicos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
    selectCliente(term: string) {
      this.clienteSelected = this.clientes.filter((cliente: Cliente) => {
        return cliente.descripcion.toLowerCase().includes(term.toLowerCase());
      });
      this.recibo.rncReceptor = this.clienteSelected[0].rnc;
      this.recibo.nombreRazonSocialReceptor =
        this.clienteSelected[0].razonSocial;
      this.asignarNCF();
    },
    async loadClientes() {
      try {
        const res = await getClientes();
        this.clientes = res.data;
      } catch (error) {
        // console.error(error);
      }
    },

    async loadOneRecibo() {
      try {
        const res = await getOneRecibo();
        this.oneRecibo = res.data;
        this.one = this.oneRecibo[0];
        this.nextNo = this.one.no + 1;
        this.recibo.no = this.nextNo;
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveReciboa() {
      try {
        const res = await createReciboa(this.servicio);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    async saveRecibo() {
      // this.handleUpdate();
      //------------------------------handleUpdate()--------------------------------
      alert("Recibo Generado Exitosamente.");
      // if (this.recibo.literal == "B15") {
      //   this.medicoSelected[0].compGuberAsig += 1;
      // } else {
      //   this.medicoSelected[0].compNoGuberAsig += 1;
      // }
      // this.medicoSelected[0].estado.pagado += this.recibo.bruto;
      // this.medicoSelected[0].estado.retension += this.recibo.retension;
      // this.medicoSelected[0].estado.ingresos += this.recibo.neto;
      // this.medicoSelected[0].estado.porCobrar -= this.recibo.bruto;
      // await updateMedico(this.medicoSelected[0]._id, this.medicoSelected[0]);

      //----------------------------------------------------------------------------
      try {
        try {
          const res = await getOneRecibo();
          this.oneRecibo = res.data;
          this.one = this.oneRecibo[0];
          this.nextNo = this.one.no + 1;
          this.recibo.no = this.nextNo;
        } catch (error) {
          // // console.error(error);
        }
        this.recibo.userReg = this.$store.state.user.usuario;
        // Cargar Balance----------------------------------------
        this.balance.medico = this.recibo.medico;
        this.balance.cliente = this.recibo.cliente;
        this.balance.fecha = this.recibo.fechaPago;
        this.balance.facturado = 0;
        this.balance.facturas = 0;
        this.balance.pagado = this.recibo.bruto;
        this.balance.recibos = 1;
        this.balance.balance = this.recibo.bruto * -1;
        this.balance.documentos = -1;
        // -------------------------------------------------------
        const res2 = await createBalance(this.balance);
        const res = await createRecibo(this.recibo);
        // // console.log(res);
        this.$router.push("/recibos/");
        // this.definingFields();
        // this.focus();
      } catch (error) {
        // // console.error(error);
      }
    },

    async deleteAllRecibos() {
      try {
        const res = await eliminateRecibos(this.recibo);
        // // console.log(res);
      } catch (error) {
        // // console.error(error);
      }
    },

    definingFields() {
      // this.recibo.emisor = "";
      // this.recibo.direccionEmisor = "";
      // this.recibo.rncEmisor = "";
      // this.recibo.fechaRecibo = "";
      // this.recibo.fechaEntrega = "";
      this.recibo.ncf = "";
      this.recibo.vencimiento = "";
      this.recibo.rncReceptor = "";
      this.recibo.nombreRazonSocialReceptor = "";
      this.recibo.totalGeneral = "";
      this.descripcionCliente = "";
    },

    focus() {
      document.getElementById("emisor").focus();
    },
  },
});
