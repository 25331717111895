
import Filters from "@/components/Documento/FiltersComponent.vue";
import ListadoComponent from "@/components/DocumentoRec/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Documento } from "@/interfaces/Documento";
import {
  getDocumentosRec,
  getDocumentosRecbyMed,
} from "@/services/DocumentoService";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      cargando: false,
      documentos: [] as Documento[],
      str: "",
      type: "",
      documento: {} as Documento,
    };
  },

  methods: {
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    async filterDocumentos(catName: string) {
      try {
        const res = await getDocumentosRec();
        this.documentos = res.data;
      } catch (error) {
        // console.error(error);
      }
      // if (catName !== "Todos") {
      //   this.documentos = this.documentos.filter((documento: Documento) => {
      //     return documento.tipo === catName;
      //   });
      // }
    },

    async search(term: string) {
      this.toggleLoading();
      this.documento.medico = term;
      try {
        const res = await getDocumentosRecbyMed(this.documento);
        this.documentos = res.data;
      } catch (error) {
        // console.error(error);
      }
      if (term !== "Todos") {
        this.documentos = this.documentos.filter((documento: Documento) => {
          return documento.medico.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadDocumentos() {
      try {
        const res = await getDocumentosRec();
        this.documentos = res.data;
      } catch (error) {
        // console.error(error);
      }
    },
  },

  mounted() {
    // this.loadDocumentos();
  },
};
