import { createRouter, createWebHistory } from "vue-router";

import store from "../store";

import GuestLayout from "../layouts/GuestLayout.vue";
import Home from "../views/HomeView.vue";
import About from "../views/AboutView.vue";
import Login from "../views/LoginView.vue";

import DashboardLayout from "../layouts/DashboardLayout.vue";
import Settings from "../views/SettingsView.vue";
import Dashboard from "../views/Monitor.vue";

// ----------------------------- Vista de Médicos -----------------------------
import Reports from "../views/ReportsView.vue";
import ReportsFac from "../views/ReportsFacView.vue";
import ReportsFac2 from "../views/ReportsFac2View.vue";
import ReportsFac3 from "../views/ReportsFac3View.vue";
import ReportsRec from "../views/ReportsRecView.vue";
import ReportsRec2 from "../views/ReportsRec2View.vue";
import ReportsRec3 from "../views/ReportsRec3View.vue";
import ReportsBal from "../views/ReportsBalView.vue";
import ReportsFacInt from "../views/ReportsFacIntView.vue";
// ----------------------------- **************** -----------------------------

// ----------------------------- Vistas Administrativas -----------------------------
import Monitor from "../views/Monitor.vue";
import cuentasporcobrar from "../views/cuentasporcobrar.vue";
// ----------------------------- **************** -----------------------------

// Documento -------------------------------------------------------------------
import DocumentoComponent from "../components/Documento/PrincipalComponent.vue";
import DocumentoDetailComponent from "../components/Documento/DetailComponent.vue";
import DocumentoPrintComponent from "../components/Documento/PrintComponent.vue";
import DocumentoFormComp from "../components/Documento/FormComp.vue";
// ----------------------------------------------------------------------------

// DocumentoRec -------------------------------------------------------------------
import DocumentoRecComponent from "../components/DocumentoRec/PrincipalComponent.vue";
import DocumentoRecDetailComponent from "../components/DocumentoRec/DetailComponent.vue";
import DocumentoRecPrintComponent from "../components/DocumentoRec/PrintComponent.vue";
import DocumentoRecFormComp from "../components/DocumentoRec/FormComp.vue";
import DocumentoReccopFormComp from "../components/DocumentoRec/FormCompcop.vue";
import DocumentoRecpriFormComp from "../components/DocumentoRec/FormComppri.vue";
// ----------------------------------------------------------------------------

// DocumentoLot -------------------------------------------------------------------
import DocumentoLotComponent from "../components/DocumentoLot/PrincipalComponent.vue";
import DocumentoLotDetailComponent from "../components/DocumentoLot/DetailComponent.vue";
import DocumentoLotPrintComponent from "../components/DocumentoLot/PrintComponent.vue";
import DocumentoLotPrintComponent2 from "../components/DocumentoLot/PrintComponent2.vue";
import DocumentoLotFormComp from "../components/DocumentoLot/FormComp.vue";
// ----------------------------------------------------------------------------

// DocumentoFac -------------------------------------------------------------------
import DocumentoFacComponent from "../components/DocumentoFac/PrincipalComponent.vue";
import DocumentoFacDetailComponent from "../components/DocumentoFac/DetailComponent.vue";
import DocumentoFacPrintComponent from "../components/DocumentoFac/PrintComponent.vue";
import DocumentoFacFormComp from "../components/DocumentoFac/FormComp.vue";
// ----------------------------------------------------------------------------

// DocumentoReFac -------------------------------------------------------------------
import DocumentoReFacComponent from "../components/DocumentoReFac/PrincipalComponent.vue";
import DocumentoReFacDetailComponent from "../components/DocumentoReFac/DetailComponent.vue";
import DocumentoReFacPrintComponent from "../components/DocumentoReFac/PrintComponent.vue";
import DocumentoReFacFormComp from "../components/DocumentoReFac/FormComp.vue";
// ----------------------------------------------------------------------------

// DocumentoNd -------------------------------------------------------------------
import DocumentoNdComponent from "../components/DocumentoNd/PrincipalComponent.vue";
import DocumentoNdDetailComponent from "../components/DocumentoNd/DetailComponent.vue";
import DocumentoNdPrintComponent from "../components/DocumentoNd/PrintComponent.vue";
import DocumentoNdFormComp from "../components/DocumentoNd/FormComp.vue";
// ----------------------------------------------------------------------------

// DocumentoNc -------------------------------------------------------------------
import DocumentoNcComponent from "../components/DocumentoNc/PrincipalComponent.vue";
import DocumentoNcDetailComponent from "../components/DocumentoNc/DetailComponent.vue";
import DocumentoNcPrintComponent from "../components/DocumentoNc/PrintComponent.vue";
import DocumentoNcFormComp from "../components/DocumentoNc/FormComp.vue";
// ----------------------------------------------------------------------------

// DocumentoNp -------------------------------------------------------------------
import DocumentoNpComponent from "../components/DocumentoNp/PrincipalComponent.vue";
import DocumentoNpDetailComponent from "../components/DocumentoNp/DetailComponent.vue";
import DocumentoNpPrintComponent from "../components/DocumentoNp/PrintComponent.vue";
import DocumentoNpFormComp from "../components/DocumentoNp/FormComp.vue";
// ----------------------------------------------------------------------------

// DocumentoRe -------------------------------------------------------------------
import DocumentoReComponent from "../components/DocumentoRe/PrincipalComponent.vue";
import DocumentoReDetailComponent from "../components/DocumentoRe/DetailComponent.vue";
import DocumentoRePrintComponent from "../components/DocumentoRe/PrintComponent.vue";
import DocumentoReFormComp from "../components/DocumentoRe/FormComp.vue";
// ----------------------------------------------------------------------------

// Recibo -------------------------------------------------------------------
import ReciboComponent from "../components/Recibo/ReciboComponent.vue";
import ReciboDetailComponent from "../components/Recibo/DetailComponent.vue";
import ReciboPrintComponent from "../components/Recibo/PrintComponent.vue";
import ReciboFormComp from "../components/Recibo/FormComp.vue";
// ----------------------------------------------------------------------------

// Factura -------------------------------------------------------------------
import FacturaComponent from "../components/Factura/FacturaComponent.vue";
import FacturaDetailComponent from "../components/Factura/DetailComponent.vue";
import FacturaPrintComponent from "../components/Factura/PrintComponent.vue";
import FacturaFormComp from "../components/Factura/FormComp.vue";
// ----------------------------------------------------------------------------

// Cliente -------------------------------------------------------------------
import ClienteComponent from "../components/Cliente/ClienteComponent.vue";
import DetailComponent from "../components/Cliente/DetailComponent.vue";
import PrintComponent from "../components/Cliente/PrintComponent.vue";
import FormComp from "../components/Cliente/FormComp.vue";
// ----------------------------------------------------------------------------

// Medico -------------------------------------------------------------------
import MedicoComponent from "../components/Medico/MedicoComponent.vue";
import MedicoDetailComponent from "../components/Medico/DetailComponent.vue";
import MedicoPrintComponent from "../components/Medico/PrintComponent.vue";
import MedicoFormComp from "../components/Medico/FormComp.vue";
// ----------------------------------------------------------------------------

// Directorio -------------------------------------------------------------------
import DirectorioComponent from "../components/Directorio/DirectorioComponent.vue";
import DirectorioDetailComponent from "../components/Directorio/DetailComponent.vue";
import DirectorioPrintComponent from "../components/Directorio/PrintComponent.vue";
import DirectorioFormComp from "../components/Directorio/FormComp.vue";
// ----------------------------------------------------------------------------

// Enlace -------------------------------------------------------------------
import EnlaceComponent from "../components/Enlace/EnlaceComponent.vue";
import EnlaceDetailComponent from "../components/Enlace/DetailComponent.vue";
import EnlacePrintComponent from "../components/Enlace/PrintComponent.vue";
import EnlaceFormComp from "../components/Enlace/FormComp.vue";
// ----------------------------------------------------------------------------

// Recepcion -------------------------------------------------------------------
import RecepcionComponent from "../components/Recepcion/RecepcionComponent.vue";
import RecepcionDetailComponent from "../components/Recepcion/DetailComponent.vue";
import RecepcionPrintComponent from "../components/Recepcion/PrintComponent.vue";
import RecepcionFormComp from "../components/Recepcion/FormComp.vue";
// ----------------------------------------------------------------------------

// Usuario -------------------------------------------------------------------
import UsuarioComponent from "../components/Usuario/UsuarioComponent.vue";
import UsuarioDetailComponent from "../components/Usuario/DetailComponent.vue";
import UsuarioPrintComponent from "../components/Usuario/PrintComponent.vue";
import UsuarioFormComp from "../components/Usuario/FormComp.vue";
// ----------------------------------------------------------------------------

const routes = [
  {
    path: "/",
    component: GuestLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/about",
        name: "About",
        component: About,
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
    ],
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          roles: ["Administrador", "Standard", "Médico", "Digitador"],
        },
      },

      // Clientes--------------------------------------------------------------------
      {
        path: "/clientes",
        name: "clientes",
        component: ClienteComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/clientes/:id",
        name: "cliente-details",
        component: DetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/clientes2/:id",
        name: "cliente-details2",
        component: PrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/clientes/new",
        name: "clientes-form",
        component: FormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // Recibos --------------------------------------------------------------------
      {
        path: "/recibos",
        name: "recibos",
        component: ReciboComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/recibos/:id",
        name: "recibo-details",
        component: ReciboDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/recibos2/:id",
        name: "recibo-details2",
        component: ReciboPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/recibos/new",
        name: "recibos-form",
        component: ReciboFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // Documentos --------------------------------------------------------------------
      {
        path: "/documentos",
        name: "documentos",
        component: DocumentoComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentos/:id",
        name: "documento-details",
        component: DocumentoDetailComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentos2/:id",
        name: "documento-details2",
        component: DocumentoPrintComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentos/new",
        name: "documentos-form",
        component: DocumentoFormComp,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },

      // ----------------------------------------------------------------------------

      // DocumentosRec --------------------------------------------------------------------
      {
        path: "/documentosrec",
        name: "documentosrec",
        component: DocumentoRecComponent,
        meta: {
          roles: ["Administrador", "Standard", "Digitador"],
        },
      },
      {
        path: "/documentosrec/:id",
        name: "documentorec-details",
        component: DocumentoRecDetailComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentosrec2/:id",
        name: "documentorec-details2",
        component: DocumentoRecPrintComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentosrec/new",
        name: "documentosrec-form",
        component: DocumentoRecFormComp,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentosreccop/new",
        name: "documentosreccop-form",
        component: DocumentoReccopFormComp,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentosrecpri/new",
        name: "documentosrecpri-form",
        component: DocumentoRecpriFormComp,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },

      // ----------------------------------------------------------------------------

      // DocumentosLot --------------------------------------------------------------------
      {
        path: "/documentoslot",
        name: "documentoslot",
        component: DocumentoLotComponent,
        meta: {
          roles: ["Administrador", "Standard", "Digitador"],
        },
      },
      {
        path: "/documentoslot/:id",
        name: "documentolot-details",
        component: DocumentoLotDetailComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentoslot2/:id",
        name: "documentolot-details2",
        component: DocumentoLotPrintComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentoslot3/:id",
        name: "documentolot-details3",
        component: DocumentoLotPrintComponent2,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentoslot/new",
        name: "documentoslot-form",
        component: DocumentoLotFormComp,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },

      // ----------------------------------------------------------------------------

      // DocumentosFac --------------------------------------------------------------------
      {
        path: "/documentosfac",
        name: "documentosfac",
        component: DocumentoFacComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentosfac/:id",
        name: "documentofac-details",
        component: DocumentoFacDetailComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentosfac2/:id",
        name: "documentofac-details2",
        component: DocumentoFacPrintComponent,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },
      {
        path: "/documentosfac/new",
        name: "documentosfac-form",
        component: DocumentoFacFormComp,
        meta: {
          roles: ["Administrador", "Digitador"],
        },
      },

      // ----------------------------------------------------------------------------

      // DocumentosReFac --------------------------------------------------------------------
      {
        path: "/documentosrefac",
        name: "documentosrefac",
        component: DocumentoReFacComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/documentosrefac/:id",
        name: "documentorefac-details",
        component: DocumentoReFacDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosrefac2/:id",
        name: "documentorefac-details2",
        component: DocumentoReFacPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosrefac/new",
        name: "documentosrefac-form",
        component: DocumentoReFacFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // DocumentosNd --------------------------------------------------------------------
      {
        path: "/documentosnd",
        name: "documentosnd",
        component: DocumentoNdComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/documentosnd/:id",
        name: "documentond-details",
        component: DocumentoNdDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosnd2/:id",
        name: "documentond-details2",
        component: DocumentoNdPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosnd/new",
        name: "documentosnd-form",
        component: DocumentoNdFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // DocumentosNc --------------------------------------------------------------------
      {
        path: "/documentosnc",
        name: "documentosnc",
        component: DocumentoNcComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/documentosnc/:id",
        name: "documentonc-details",
        component: DocumentoNcDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosnc2/:id",
        name: "documentonc-details2",
        component: DocumentoNcPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosnc/new",
        name: "documentosnc-form",
        component: DocumentoNcFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // DocumentosNp --------------------------------------------------------------------
      {
        path: "/documentosnp",
        name: "documentosnp",
        component: DocumentoNpComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/documentosnp/:id",
        name: "documentonp-details",
        component: DocumentoNpDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosnp2/:id",
        name: "documentonp-details2",
        component: DocumentoNpPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosnp/new",
        name: "documentosnp-form",
        component: DocumentoNpFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // DocumentosRe --------------------------------------------------------------------
      {
        path: "/documentosre",
        name: "documentosre",
        component: DocumentoReComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/documentosre/:id",
        name: "documentore-details",
        component: DocumentoReDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosre2/:id",
        name: "documentore-details2",
        component: DocumentoRePrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/documentosre/new",
        name: "documentosre-form",
        component: DocumentoReFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // Facturas --------------------------------------------------------------------
      {
        path: "/facturas",
        name: "facturas",
        component: FacturaComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/facturas/:id",
        name: "factura-details",
        component: FacturaDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/facturas2/:id",
        name: "factura-details2",
        component: FacturaPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/facturas/new",
        name: "facturas-form",
        component: FacturaFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // Médicos --------------------------------------------------------------------
      {
        path: "/medicos",
        name: "medicos",
        component: MedicoComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/medicos/:id",
        name: "medico-details",
        component: MedicoDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/medicos2/:id",
        name: "medico-details2",
        component: MedicoPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/medicos/new",
        name: "medicos-form",
        component: MedicoFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // Directorios--------------------------------------------------------------------
      {
        path: "/directorios",
        name: "directorios",
        component: DirectorioComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/directorios/:id",
        name: "directorio-details",
        component: DirectorioDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/directorios2/:id",
        name: "directorio-details2",
        component: DirectorioPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/directorios/new",
        name: "directorios-form",
        component: DirectorioFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // Enlaces--------------------------------------------------------------------
      {
        path: "/enlaces",
        name: "enlaces",
        component: EnlaceComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/enlaces/:id",
        name: "enlace-details",
        component: EnlaceDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/enlaces2/:id",
        name: "enlace-details2",
        component: EnlacePrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/enlaces/new",
        name: "enlaces-form",
        component: EnlaceFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // Recepcions--------------------------------------------------------------------
      {
        path: "/recepcions",
        name: "recepcions",
        component: RecepcionComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/recepcions/:id",
        name: "recepcion-details",
        component: RecepcionDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/recepcions2/:id",
        name: "recepcion-details2",
        component: RecepcionPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/recepcions/new",
        name: "recepcions-form",
        component: RecepcionFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      // Usuarios --------------------------------------------------------------------
      {
        path: "/usuarios",
        name: "usuarios",
        component: UsuarioComponent,
        meta: {
          roles: ["Administrador", "Standard"],
        },
      },
      {
        path: "/usuarios/:id",
        name: "usuario-details",
        component: UsuarioDetailComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/usuarios2/:id",
        name: "usuario-details2",
        component: UsuarioPrintComponent,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/usuarios/new",
        name: "usuarios-form",
        component: UsuarioFormComp,
        meta: {
          roles: ["Administrador"],
        },
      },

      // ----------------------------------------------------------------------------

      {
        path: "/monitor",
        name: "Monitor",
        component: Monitor,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
        meta: {
          roles: ["Médico"],
        },
      },
      {
        path: "/reportsfac",
        name: "ReportsFac",
        component: ReportsFac,
        meta: {
          roles: ["Médico"],
        },
      },
      {
        path: "/reportsfac2",
        name: "ReportsFac2",
        component: ReportsFac2,
        meta: {
          roles: ["Médico"],
        },
      },
      {
        path: "/reportsfac3",
        name: "ReportsFac3",
        component: ReportsFac3,
        meta: {
          roles: ["Médico"],
        },
      },
      {
        path: "/reportsrec",
        name: "ReportsRec",
        component: ReportsRec,
        meta: {
          roles: ["Médico"],
        },
      },
      {
        path: "/reportsrec2",
        name: "ReportsRec2",
        component: ReportsRec2,
        meta: {
          roles: ["Médico"],
        },
      },
      {
        path: "/reportsrec3",
        name: "ReportsRec3",
        component: ReportsRec3,
        meta: {
          roles: ["Médico"],
        },
      },
      {
        path: "/reportsbal",
        name: "ReportsBal",
        component: ReportsBal,
        meta: {
          roles: ["Médico"],
        },
      },
      {
        path: "/documentosfacint",
        name: "documentosfacint",
        component: ReportsFacInt,
        meta: {
          roles: ["Administrador"],
        },
      },
      {
        path: "/cuentasporcobrar",
        name: "cuentasporcobrar",
        component: cuentasporcobrar,
        meta: {
          roles: ["Administrador"],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  if (to.meta?.requiresAuth && store.state.isAuthenticated) {
    // let users enter if authenticated
    if (to.meta?.roles?.includes(store.state.user.type)) {
      next();
    } else {
      // console.log('User is not authorized for route.')
      alert("Usuario no Autorizado.");
    }
  } else if (to.meta?.requiresAuth) {
    // otherwise, route them to /login
    next("/");
  } else {
    next();
  }
});

export default router;
