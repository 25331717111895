
import Navbar from "@/components/Navbar.vue";
export default {
  name: 'LoginView',
  components: {
    Navbar,
  },

  data() {
    return {
      role: ""
    };
  },

  methods: {
    login() {
      this.$store.dispatch('loginAction')
      this.$store.state.user.type = this.role
      this.$router.push("/dashboard");
    }
  }
}
